import { ensureUserAuthenticated } from '@/backend/ensureUserSignedIn';
import { db } from '@/backend/firebase';
import { appContext } from '@/context/appContext';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { doc, getDoc } from 'firebase/firestore';

const paymentUrls = {
  accessPS1: 'https://buy.stripe.com/aEUdSn00b5R107u4gm',
  accessBeta: 'https://buy.stripe.com/6oEdSn3cndjt3jGfZ6',
  fiftyCent: 'https://buy.stripe.com/00g4hN9AL0wH3jG4gq',
  subStarter: 'https://buy.stripe.com/aEUcOjeV5cfp3jG14f', // $99
  subStarterPlus: 'https://buy.stripe.com/9AQbKfbITa7h9I400c', // $199
  subStarter_test: 'https://buy.stripe.com/test_00geYD21ifO3bwA3cf', // $99
  subStarterPlus_test: 'https://buy.stripe.com/test_fZe7wbeO431h8kocMQ', // $199
};

export type StripePaymentItemKey = keyof typeof paymentUrls;

// const paymentUrlBase = 'https://buy.stripe.com/test_fZe6rhaTE4gkga4fYY';
// const paymentUrlBase = 'https://buy.stripe.com/aEUdSn00b5R107u4gm';
// const paymentUrlBase = 'https://buy.stripe.com/test_dR6g2HfS89pF8ko3ce';
// const paymentUrlBase = 'https://buy.stripe.com/test_fZe17NfS86dteIMaEF';

export function createStripePaymentService(itemKey: StripePaymentItemKey) {
  if (appContext.urlParams.payFiftyCentsOnly) {
    console.log('👛 Paying only $.50, per dev param');
    itemKey = 'fiftyCent';
  }

  async function performStripePayment() {
    const awaitable = createCustomAwaitable<void>();

    const userId = await ensureUserAuthenticated();
    const userEmail = appContext.userData.profile.proxy.email || '';

    const paymentUrlBase = paymentUrls[itemKey];
    const paymentUrlParams = new URLSearchParams(
      Object.entries({
        prefilled_email: userEmail,
        client_reference_id: userId,
        user_auth_id: userId,
      })
    );
    const paymentUrl = paymentUrlBase + '?' + paymentUrlParams.toString();

    const paymentWindow = window.open(paymentUrl, '_blank', 'width=500,height=600');

    if (!paymentWindow) {
      throw new Error('Failed to open payment window');
    }

    const pollTimer = window.setInterval(async () => {
      if (!paymentWindow.closed) {
        console.log('🔄 Polling payment window...');
        return;
      }

      console.log('🔄 Payment window closed');
      clearInterval(pollTimer);

      awaitable.resolve();
    }, 500);

    await awaitable;

    checkIfUserPaidSuccessfully().then(paid => {
      if (paid) {
        appContext.events.purchaseCompleted.emit(itemKey);
      }
    });
  }

  async function checkIfUserPaidSuccessfully() {
    const field = itemKey;
    const userId = await ensureUserAuthenticated();

    //// Check firestore for purchases - UserPurchases.[user id].threeDollarSub should be true
    const collection = 'UserPurchases';
    const docRef = doc(db, collection, userId);
    const purchases = await getDoc(docRef);

    if (!purchases.exists) {
      return false;
    }

    const purchasesData = purchases.data();
    if (!purchasesData) {
      return false;
    }

    const hasPaid = purchasesData[field];
    console.log('🎤 User has paid:', hasPaid);

    return Boolean(hasPaid);
  }

  return {
    performStripePayment,
    checkIfUserPaidSuccessfully,
  };
}
