import { appContext } from '@/context/appContext';
import { createStripePaymentService, StripePaymentItemKey } from '@/services/createStripePaymentService';
import { SubscriptionScreen } from './components/SubscriptionScreen';

export async function displaySubscriptionScreen(parentElement: HTMLElement, testMode: boolean = false) {
  appContext.events.displaySplashScreen.emit('share');

  const screen = new SubscriptionScreen();
  parentElement.append(screen);

  function getPaymentItemKeyFromPlanName(planName: string) {
    if (planName === 'STARTER') return !testMode ? 'subStarter' : 'subStarter_test';
    if (planName === 'STARTER PLUS') return !testMode ? 'subStarterPlus' : 'subStarterPlus_test';
    return null;
  }

  let paymentItemKey: StripePaymentItemKey | null = null;

  while (paymentItemKey === null) {
    const plan = await screen.waitUntilPlanChosen();
    console.log('🚀 Subscription plan chosen', plan);

    paymentItemKey = getPaymentItemKeyFromPlanName(plan.title);

    if (paymentItemKey === null) {
      alert(`Plan "${plan.title}" not available yet. Please choose a different plan.`);
      continue;
    }

    appContext.events.subscriptionCheckoutStarted.emit(paymentItemKey);

    const paymentService = createStripePaymentService(paymentItemKey);
    await paymentService.performStripePayment();

    console.log('🎤 Performing payment');
    const paid = await paymentService.checkIfUserPaidSuccessfully();
    console.log('🎤 Payment result:', paid);

    if (!paid) {
      appContext.events.subscriptionCheckoutCompleted.emit(paymentItemKey);
      paymentItemKey = null;
    }
  }

  screen.remove();
}
