import gsap from 'gsap';
import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import { trackOrientationAsCSSClass } from '@/helpers/trackOrientationAsCSSClass';

import '../common/GlassButton';

const TAG_NAME = 'completion-screen';

@customElement(TAG_NAME)
export class CompletionScreen extends LitElement {
  static styles = css`
    :host {
      --splash-screen-padding: 0vmin;

      position: absolute;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Helvetica Neue', sans-serif;
    }

    .container {
      box-sizing: border-box;
      text-align: center;
      color: white;
      position: absolute;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);
      padding: 2rem;
    }

    .title {
      font-size: 3em;
      text-shadow: 0 2px 1rem #0009;
      margin-bottom: 1rem;
      font-family: 'Lora', serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
    }

    .message {
      font-size: 1.2em;
      text-shadow: 0 2px 1rem #0009;
      font-family: 'Inter', sans-serif;
      font-optical-sizing: auto;
      font-weight: 300;
      font-style: normal;
      color: #cdcdcd;
      max-width: 720px;
      margin-bottom: 1rem;
    }

    .postscript {
      font-size: 1em;
      font-style: italic;
      margin-top: 2rem;
    }

    glass-button {
      margin-top: 2rem;
      width: 640px;
      max-width: 80%;
    }

    :host(.portrait) {
      .title {
        font-size: 2rem;
      }
      .message,
      .postscript {
        font-size: 0.9rem;
      }
    }
  `;

  @property({ type: String })
  shifterName = '';

  @query('.container')
  public readonly containerElement!: HTMLElement;

  protected firstUpdated(): void {
    trackOrientationAsCSSClass(this);
  }

  protected render() {
    return html`
      <boomerang-video-background></boomerang-video-background>
      <div class="container">
        <div class="title">Congratulations, ${this.shifterName}!</div>
        <div class="message">Congrats on completing another Presence Shift!</div>
        <div class="message">Keep an eye on your email for your next Presence Shift.</div>
        <div class="message">We'll be in touch soon.</div>
        <div class="postscript">P.S. Share The Presence Shift with someone you care about…</div>
      </div>
    `;
  }

  public async fadeIn(fadeDuration = 1) {
    await gsap.from(this, { duration: fadeDuration, opacity: 0 });
  }

  public async fadeOutAndRemove(fadeDuration = 1) {
    await gsap.to(this, { duration: fadeDuration, opacity: 0 });
    this.remove();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: CompletionScreen;
  }
}
