import { MusicTracks } from './audio/music';
import { checkUserSignedInNotAnonymously, ensureUserAuthenticated } from './backend/ensureUserSignedIn';
import { buildVars } from './constants/buildVars';
import { appContext } from './context/appContext';
import { createSceneContext } from './context/sceneContext';
import { initializeDevThingsForApp } from './debug/initializeDevThingsForApp';
import { runDevSandbox } from './debug/runDevSandbox';
import { getIsUserOnAnySubscriptionPlan } from './helpers/getIsUserOnAnySubscriptionPlan';
import { trackOrientationAsCSSClass } from './helpers/trackOrientationAsCSSClass';
import { addSettingsButtonAndDialog } from './html/addSettingsButtonAndDialog';
import { CompletionScreen } from './html/components/CompletionScreen';
import { displayGetNextPresenceShiftSplash } from './html/displayGetNextPresenceShiftSplash';
import { displayLearnMoreSplash_EndVersion } from './html/displayLearnMoreSplash';
import { displaySigningSplash } from './html/displaySigningSplash';
import { displaySubscriptionScreen } from './html/displaySubscriptionScreen';
import { displayWelcomeScreenAndIntroVideo } from './html/displayWelcomeScreenAndIntroVideo';
import { addAppVersionIndicator } from './html/system/addAppVersionIndicator';
import { displayErrorMessage } from './html/system/displayErrorMessage';
import { defaultVars } from './misc/defaultVars';
import { runPlayer } from './player';
import { ensureAllAssetsLoaded } from './scene/ensureAllAssetsLoaded';
import { captureUTMParams } from './tools/captureUTMParams';
import { disableZoomGestures } from './util/browser/disableZoomGestures';
import { performPasswordProtectionCheck } from './util/performPasswordProtectionCheck';

import '@/sentry/sentry';

import '@/styles/index.scss';
import '@/styles/keyframes.scss';
import '@/styles/mobile.scss';
import '@/styles/ui.scss';

const APP_VERSION = buildVars.appBuildVersion ?? 'unknown';

initializeDevThingsForApp();

disableZoomGestures();

function resolvePossibleSlugAlias(possibleAliasSlug: string) {
  const scriptAliases = {
    intro: scriptsQueue[0] ?? 'intro-oct-24.1',
    ps1: scriptsQueue[1] ?? 'ps1-short',
  } as Record<string, string>;
  return scriptAliases[possibleAliasSlug] ?? possibleAliasSlug;
}

const scriptsQueue = getScriptsQueue();

const urlHash = window.location.hash.slice(1);
const slugToSkipToRaw = /^\/?play/.test(urlHash) ? urlHash.split('/').pop() : undefined;
const slugToSkipTo = slugToSkipToRaw ? resolvePossibleSlugAlias(slugToSkipToRaw) : undefined;
// console.log('🚀', { urlHash, slugToSkipToRaw, slugToSkipTo, scriptsQueueString:  appContext.urlParams.queue || buildVars.scripts });

export async function main() {
  const { appDiv, urlParams, userData, events, music } = appContext;

  async function signInMaybe() {
    if (!buildVars.gates.signIn) return;

    const isLoggedIn = await checkUserSignedInNotAnonymously();
    if (isLoggedIn) return;

    await displaySigningSplash(appDiv);
  }

  requestAnimationFrame(() =>
    captureUTMParams((key, value) => {
      gtag('set', { [key]: value });
    })
  );

  console.log(`🎤 App version: ${APP_VERSION}`);
  addAppVersionIndicator(appDiv, APP_VERSION);

  console.log('🚀 Starting...');

  ensureAllAssetsLoaded();

  trackOrientationAsCSSClass(appDiv);

  const onFirstClick = () => {
    console.log('🎶 Playing music');
    music.playTrack(MusicTracks.Main);
    window.removeEventListener('click', onFirstClick);
  };
  window.addEventListener('click', onFirstClick);

  try {
    await ensureUserAuthenticated();
  } catch (error) {
    console.error(error);
    displayErrorMessage(String(error), 'Signing in failed.');
  }

  if (!urlParams.skipSplash) {
    await displayWelcomeScreenAndIntroVideo(appDiv, !!slugToSkipTo);
  }

  //// Mute the sound if necessary
  async function getShouldMute() {
    if (urlParams.mute !== undefined) return urlParams.mute;
    return false;
  }

  const shouldMute = await getShouldMute();
  appContext.setMuted(shouldMute);

  ////

  {
    const settingsElements = addSettingsButtonAndDialog(appDiv);

    const scriptItemTagToShowMenuButtonAt = 'got_shifter_name';
    const onSettingsButtonShownCallbacks = [] as (() => unknown)[];
    const onSettingsButtonShown = () => onSettingsButtonShownCallbacks.forEach(cb => cb());

    const showSettingsButton = () => {
      settingsElements.fadeIn(4);
      onSettingsButtonShown();
    };

    const stopListentingForShiftStarted = events.shiftStarted.on(shiftContext => {
      const stopListentingForPlaybackStarted = shiftContext.events.playScriptStarted.on(script => {
        const scriptHasTagAtAll = script.some(item => item.tag === scriptItemTagToShowMenuButtonAt);

        if (!scriptHasTagAtAll) {
          showSettingsButton();
        } else {
          const stopListeningForScriptItemStarted = shiftContext.events.playScriptItemStarted.on(({ tag }) => {
            if (tag === scriptItemTagToShowMenuButtonAt) {
              showSettingsButton();
            }
          });
          onSettingsButtonShownCallbacks.push(stopListeningForScriptItemStarted);
        }
      });
      onSettingsButtonShownCallbacks.push(stopListentingForPlaybackStarted);
    });
    onSettingsButtonShownCallbacks.push(stopListentingForShiftStarted);
  }

  try {
    const sceneContext = await createSceneContext();
    await sceneContext.animateIn();

    const boomerangVideoBackgrounds = document.getElementsByTagName('boomerang-video-background');
    if (boomerangVideoBackgrounds.length > 0) {
      console.warn('🐬 Found boomerang video backgrounds:', boomerangVideoBackgrounds);
      for (const boomerangVideoBackground of boomerangVideoBackgrounds) {
        boomerangVideoBackground.remove();
      }
    }

    ////
    //// SHIFT SCRIPTS PLAYBACK
    ////

    if (urlHash === 'subscribe') {
      const isUserOnAnySubscriptionPlan = await getIsUserOnAnySubscriptionPlan();
      if (!isUserOnAnySubscriptionPlan) {
        await displaySubscriptionScreen(appDiv);
      }
    }

    if (appContext.urlParams.play !== null) {
      ////
      //// SHIFT SCRIPTS PLAYBACK EXPLICIT
      ////

      await signInMaybe();

      const playSlug = appContext.urlParams.play;
      await runPlayer(playSlug, sceneContext);

      const screen = new CompletionScreen();
      appDiv.appendChild(screen);
      screen.fadeIn();
    } else {
      ////
      //// SHIFT SCRIPTS PLAYBACK DEFAULT
      ////

      //// INTRO
      const skipIntro = slugToSkipTo?.includes('ps1');
      if (!skipIntro) {
        const introSlug = buildVars.cinematics.intro;
        if (!introSlug) throw new Error('No intro slug found');

        await runPlayer(introSlug, sceneContext);
      }

      //// SIGN-IN
      await signInMaybe();

      //// PS1
      const ps1Slug = buildVars.cinematics.ps1;
      if (!ps1Slug) throw new Error('No PS1 slug found');

      await runPlayer(ps1Slug, sceneContext);

      //// SUBSCRIBE

      const isUserOnAnySubscriptionPlan = await getIsUserOnAnySubscriptionPlan();
      if (!isUserOnAnySubscriptionPlan) {
        await displaySubscriptionScreen(appDiv);
      }

      if (buildVars.gates.subForPS2) {
        if (!userData.profile.proxy.phone) {
          const storedShifterName = await userData.profile.getFieldValue('name');
          const shifterName = storedShifterName ?? defaultVars.shifter_name;
          await displayGetNextPresenceShiftSplash(appDiv, shifterName);
        }
      }

      ////
      //// THE END (show learn more splash)
      ////

      if (buildVars.gates.learnModeEndScr) {
        const storedShifterName = await userData.profile.getFieldValue('name');
        const shifterName = storedShifterName ?? defaultVars.shifter_name;
        await displayLearnMoreSplash_EndVersion(appDiv, shifterName);
      }
    }
  } catch (error) {
    console.error(error);
    displayErrorMessage(String(error));
  }
}

function getScriptsQueue() {
  const scriptsQueueString = appContext.urlParams.queue || buildVars.scripts;
  if (!scriptsQueueString) {
    throw new Error('Shifts queue not specified');
  }

  const queue = String(scriptsQueueString)
    .replace(/\s/g, '')
    .split(',')
    .filter(s => s !== '');

  return queue;
}

if (appContext.urlParams.sandbox) {
  runDevSandbox();
} else {
  const passwordCheckPassed = performPasswordProtectionCheck();
  if (passwordCheckPassed) {
    main();
  } else {
    const { appDiv } = appContext;
    const contactEmail = atob('c2VhbkBvbmVwZXJmZWN0LmNvbQ==');
    appDiv.innerHTML = `Contact ${contactEmail} for a demo :)`;
  }
}
