import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { trackOrientationAsCSSClass } from '@/helpers/trackOrientationAsCSSClass';

import { ReusableStyles } from '../common/styles';
import { ModernInput } from './ModernInput';

import '../common/BlurryWrapper';
import '../common/PlatformLoginButton';
import '../common/SmallSpinner';
import './ModernInput';
import './TOSFooter';

const TAG_NAME = 'sign-in-form';

const defaultSignInFormData = {
  email: '',
  password: '',
};
type SignInFormData = typeof defaultSignInFormData;

type Platform = 'google' | 'apple';

@customElement(TAG_NAME)
export class SignInForm extends LitElement {
  static readonly tagName = TAG_NAME;

  static readonly styles = css`
    :host {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      zoom: 0.8;
    }

    :host(.portrait) {
      zoom: 0.55;
    }

    .form-container {
      width: 100%;
      max-width: 480px;
      margin: auto;
      border-radius: 10px;
      text-align: center;
      color: #ffffff;

      display: flex;
      flex-direction: column;
      align-items: center;

      z-index: 1;
    }

    .form-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
    }

    form {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      width: 90%;
    }

    .form-title {
      font-size: 24px;
      margin-bottom: 20px;

      color: #fff;
      text-align: center;
      font-family: Lora;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 106.667% */
      letter-spacing: 0.6px;
    }

    .form-subtitle {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;

      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    .platform-login-row {
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin-top: 1rem;
      gap: 1rem;
    }

    .platform-login-row > * {
      flex-grow: 1;
    }

    :host button.sign-up-button {
      margin-top: 1.5rem;
      width: 100%;
    }

    .small-text {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.14px;
    }

    hr {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      width: 90%;
    }

    .sign-up-link {
      margin-top: 2em;
      color: #fff;

      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: -0.2px;
      text-decoration: underline;
    }

    .signing-sign {
      margin-top: 2em;
      color: #fff;

      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: -0.2px;
      opacity: 0.3;
    }

    ${ReusableStyles.glassButton}

    button.glass-button {
      max-width: 80%;
    }
  `;

  readonly data = { ...defaultSignInFormData };

  @query('.sign-up-button')
  private readonly signUpButton!: HTMLButtonElement;

  public readonly onSignUpWithPlatformClick = createDelegateFunction<[platform: Platform]>();
  public readonly onSignUpClick = createDelegateFunction();
  public readonly onSignInClick = createDelegateFunction<[data: SignInFormData]>();

  @property({ type: Boolean }) loading = false;

  handleInputChange(event: CustomEvent) {
    if (!event.target) {
      console.error('No target found for input change event');
      return;
    }

    const inputElement = event.target as ModernInput;
    const inputName = inputElement.name;

    if (!inputName) {
      console.error('No input name found for input change event');
      return;
    }

    this.data[inputName as keyof SignInFormData] = inputElement.value;
  }

  firstUpdated(): void {
    trackOrientationAsCSSClass(this);
  }

  render() {
    return html`
      <blurry-wrapper> </blurry-wrapper>

      <div class="form-container">
        <div class="form-title">Welcome to Presence Shift 1.</div>
        <div class="form-content">
          <div class="form-subtitle">Login with</div>

          <div class="platform-login-row">
            <platform-login-button
              id="google-login-button"
              platform="Google"
              icon="/images/icons/platform-google.svg"
              @click=${() => {
                this.setBusyState(true);
                this.onSignUpWithPlatformClick.emit('google');
              }}
            ></platform-login-button>
            <platform-login-button
              id="apple-login-button"
              platform="Apple"
              icon="/images/icons/platform-apple.svg"
              @click=${() => {
                this.setBusyState(true);
                this.onSignUpWithPlatformClick.emit('apple');
              }}
              disabled
            ></platform-login-button>
          </div>

          <hr />

          <div class="small-text">Or continue with email address</div>

          <form ?disabled=${this.loading}>
            <modern-input
              name="email"
              type="email"
              placeholder="my@email.com"
              @input-change=${this.handleInputChange}
            ></modern-input>

            <modern-input
              name="password"
              type="password"
              placeholder="mypassword"
              required
              autocomplete="new-password"
              @input-change=${this.handleInputChange}
            ></modern-input>

            <button
              id="sign-in-button"
              class="sign-up-button glass-button"
              ?disabled=${this.loading}
              @click=${() => {
                this.setBusyState(true);
                this.onSignInClick.emit(this.data);
              }}
            >
              ${this.loading ? html`<small-spinner size="1em"></small-spinner>` : html`Continue`}
            </button>
          </form>

          ${this.loading
            ? html` <a class="signing-sign"> Signing up... </a> `
            : html`
                <a hr id="sign-up-link" ef="##" class="link sign-up-link" @click=${this.onSignUpClick}> Or Sign Up </a>
              `}
        </div>
      </div>
    `;
  }

  setBusyState(busy: boolean) {
    this.loading = busy;

    if (this.signUpButton) {
      this.signUpButton.disabled = busy;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: SignInForm;
  }
}
