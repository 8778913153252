type BuildVars = Record<string, string | undefined> & {
  passwordBase64: string;
  appPackageVersion: string;
  appBuildVersion: string;
  scripts: string;
  gates: {
    readyScr: boolean;
    introScr: boolean;
    welcomeScr: boolean;
    signIn: boolean;
    shareForPS1: boolean;
    subForPS2: boolean;
    learnModeEndScr: boolean;
  };

  showSubscribeForBetaPaymentLink: boolean | undefined;
  showSubstackLink: boolean | undefined;
  substackLinkUrl: string | undefined;
  showShareButtonsOnLearnMore: boolean | undefined;

  openaiApiKey: string | undefined;
  elevenLabsApiKey: string | undefined;
  elevenLabsVoiceId: string | undefined;

  flow: `${'func' | 'script'}:${string}`[] | undefined;

  require13yo: boolean | undefined;
  cinematics: {
    intro: string;
    ps1: string;
    ps2: string;
    ps3: string;
    [key: string]: string;
  };
};

export const buildVars = process.env as typeof process.env & BuildVars;
